export default [
  {
    path: '/',
    name: 'Portal',
    component: () => import('@/views/Portal/Portal.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Portal',
          active: true,
        },
      ],
    },
  },
  {
    path: '/kurumsal',
    name: 'Company',
    component: () => import('@/views/Portal/Company.vue'),
    meta: {
      pageTitle: 'Kurumsal',
      breadcrumb: [
        {
          text: 'Kurumsal',
          active: true,
        },
      ],
    },
  },
  {
    path: '/egitim',
    name: 'Education',
    component: () => import('@/views/Portal/Education.vue'),
    meta: {
      pageTitle: 'Eğitim',
      breadcrumb: [
        {
          text: 'Eğitim',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dokuman-arsivi',
    name: 'Documents',
    component: () => import('@/views/Portal/Documents.vue'),
    meta: {
      pageTitle: 'Doküman Arşivi',
      breadcrumb: [
        {
          text: 'Dokümanlar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fotograf-galerisi',
    name: 'Gallery',
    component: () => import('@/views/Portal/Gallery.vue'),
    meta: {
      pageTitle: 'Fotoğraf Galerisi',
      breadcrumb: [
        {
          text: 'Albümler',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fotograf-galerisi/:id',
    name: 'GalleryPhotos',
    component: () => import('@/views/Portal/GalleryPhotos.vue'),
    meta: {
      pageTitle: 'Fotoğraf Galerisi',
      breadcrumb: [
        {
          text: 'Fotoğraflar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/portal/photosDetail',
    name: 'photosDetail',
    component: () => import('@/views/Portal/routes/photosDetail.vue'),
    meta: {
      pageTitle: 'Galeri Detayı',
      breadcrumb: [
        {
          text: 'Portal',
          to: '/portal',
          active: false,
        },
        {
          text: 'Galeri Detayı',
          active: true,
        },
      ],
    },
  },
]
